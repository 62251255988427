<template>
    <a  
        :href="link"
        :style="{
            color: 'var(--primary-color)',
            textDecoration:'none'
        }"
        target="_blank"
    > 
        <img 
            :src="image"
            :style="{
            width: '20px',
            margin: '0 5px',
            }"
        />
    </a>
</template>

<script>
export default {
  props: {
    link: {
        type: String,
        default: 'https://hopehorizonworld.com/'
    },
    image: {
        type: String,
        default: 'assets/icons/circle.svg'
    }
  }
}
</script>

<style>

</style>
