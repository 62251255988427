import Vue from 'vue'
import VueRouter from 'vue-router'
//import Dashboard from '../views/dashboard/Dashboard.vue'
//import Covid19SeverityVue from "../views/dashboard/experiments/Covid19Severity.vue"
//import ViewExperimentsVue from "../components/Experiments/ViewExperiments.vue"
// import LoginVue from "../views/auth/Login.vue"
// import CommonVue from "../views/auth/Common.vue"
// import SignupVue from "../views/auth/Signup.vue"
//import DefaultVue from "../views/Default.vue"
//import store from "../store";
//import PersistantStorage from "../lib/storage/PersistantStorage"
import Covid19PositivityVue from "../views/dashboard/experiments/Covid19Positivity.vue"
import HomeVue from "../views/home/Home.vue"
import WebsiteLayoutVue from "../views/home/WebsiteLayout.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: WebsiteLayoutVue,
    children: [
      { path: 'covid19Positivity', component: Covid19PositivityVue, name:"covid19Positivity" },
      { path: '', component: HomeVue},
    ]
  },



  // {
  //   path: '/auth',
  //   component: CommonVue,
  //   children: [
  //     { path: 'login', component: LoginVue, name: 'login' },
  //     { path: 'signup', component: SignupVue }
  //   ]
  // },
  
  // {
  //   path: '/dashboard',
  //   component: Covid19PositivityVue,
  //   name:'dashboard',
  // },

  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   beforeEnter(to, from ,next){
  //     console.log(store)
  //     if(store.getters.getUser && store.getters.getUser()){
  //       next()
  //     }
  //     const access_token = PersistantStorage.retrieve("access_token");
  //     console.log(access_token)
  //     if(!access_token){
  //       return next({name:'login'})
  //     }
  //     store.commit("setAccessToken",access_token)
  //     store.dispatch("getUser")
  //     next()
  //   },
  //   children: [
  //     {
  //       path: 'experiments',
  //       component: DefaultVue,
  //       children: [
  //         { path: 'covid19Severity', component: Covid19SeverityVue },
  //         { path: 'covid19Positivity', component: Covid19PositivityVue },
  //         { path: '', component: ViewExperimentsVue, name:'dashboard' },
  //       ]
  //     },
  //     { path: '', component: ViewExperimentsVue },
  //   ]
  // },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
