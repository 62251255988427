import i18n from "../i18n";
import changeLang from "../lib/head/changeLang"

const RTL_LANGUAGES = ["ar"]

export default {
	namespaced: true,
	state: {
		language: 'fr',
		direction: 'ltr',
		fontSize: 15,
	},
	mutations: {
		setDirection (state, direction) {
			state.direction = direction;
		},
		setLanguage (state, language) {
			state.language = language;
			i18n.locale = language
		},
		setFontSize (state, fontSize){
			state.fontSize = fontSize;
		}
	},
	actions: {
		setLanguage (ctx, language) {
			ctx.commit("setLanguage", language)
			changeLang(language);
			if (RTL_LANGUAGES.includes(language)) {
				ctx.commit("setDirection", "rtl")
				ctx.commit("setFontSize", 17)
			} else {
				ctx.commit("setDirection", "ltr")
				ctx.commit("setFontSize", 15)
			}
		}
	},
	getters: {
		getDirection: ({ direction }) => direction,
		getLanguage: ({ language }) => language,
		getFontSize: ({fontSize})=>fontSize,
	}
}