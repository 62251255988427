var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{style:({
			width:'100%',
			marginBottom:'15px',
      textAlign:'center',
		}),attrs:{"bodyStyle":{
				padding:'15px',
			}}},[_c('h2',{style:({
			fontWeight:'bold'
		})},[_vm._v(_vm._s(_vm.$t('clickHereIfYouWouldLikeADoctorToCallYou')))]),_c('a',{attrs:{"href":"https://docs.google.com/forms/d/e/1FAIpQLSdEGRgQi2E0mgPhkx5KJDzRhRW9QxmOf2daXa1oG3F82IFxfg/viewform"}},[_c('Button',[_vm._v(" "+_vm._s(_vm.$t('fillOutTheForm'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }