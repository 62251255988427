var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display:'flex',
    maxWidth:'1000px',
    margin:'auto',
    width:'100%',
    justifyContent:'space-between',
    alignItems:'center',
    flexDirection:'row',
    padding:'15px',
})},[_c('Logo',{attrs:{"with-text":""}}),_c('language-selector')],1)}
var staticRenderFns = []

export { render, staticRenderFns }