<template>
  <div>
    <div :style="{
      display:'flex',
      flexFlow:'row wrap',
      justifyContent:'space-around',
      alignItems:'center',
      marginBottom:'50px'
    }">
      <div :style="{
        width:'100%',
        maxWidth:'500px',
        padding:'25px'
      }">
        <h1>
          {{title}}
        </h1>
        <p>{{description}}</p>
      </div>
      <img
        width="300"
        :src="photo"
      />
    </div>
    <div :style="{
      display:'flex',
      flexFlow:'row wrap',
      justifyContent:'space-around',
      alignItems:'start',
      marginBottom:'150px'
    }">
      <div :style="{
      width:'100%',
      maxWidth:'600px',
      padding:'25px'
    }">
        <p>{{formMessage}}</p>
        <component
          :is="form"
          :formInputs="formInputs"
          :submitLabel="$t('predict')"
          :getErrorMessage="getErrorMessage"
          :loading="loading"
          @submit="$listeners.submit"
          @change="$emit('change',$event)"
        />
      </div>

      <div :style="{
        margin:'15px',
      }">
        <div :style="{
          marginBottom:'15px'
        }">
          <slot title="result"></slot>
        </div>
        <Card :style="{
          marginBottom: '15px',
          flex:'1 0 300px',
          maxWidth:'400px'}">
          <Sponsors/>
        </Card>
        <Help :style="{
          marginBottom: '15px',
          flex:'1 0 300px',
          maxWidth:'400px'
        }" />
        <ContactUs :style="{
          marginBottom: '15px',
          flex:'1 0 300px',
          maxWidth:'400px'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import HelpVue from '../Form/Help.vue'
import ContactUsVue from '../Form/ContactUs.vue'
import SponsorsVue from '../Sponsorship/Sponsors.vue';
import CardVue from '../Card/Card.vue';
export default {
  components: {
    Card: CardVue,
    Help: HelpVue,
    ContactUs: ContactUsVue,
    Sponsors: SponsorsVue,
  },
  props: {
    loading:Boolean,
    title: String,
    description: String,
    photo: String,
    formMessage: String,
    form: Object,
    formInputs: Object,
  },
  methods:{
    getErrorMessage(field){
      return `${field}` + this.$t("isRequired");
    }
  }
}
</script>

<style>
</style>