<template>
  <div
    :style="{
      display: 'flex',
      textAlign: 'center',
      justifyContent: justifyContent,
    }"
  >
    <div :style="{ margin: '0 15px' }">
      <p>{{ $t("poweredBy") }}</p>
      <a href="https://hopehorizonworld.com">
        <img :width="width" src="/assets/logo/logo-hope-horizon.png" />
      </a>
    </div>
    <div :style="{ margin: '0 15px' }">
      <p>{{ $t("sponsoredBy") }}</p>
      <a href="https://www.medis.com.tn/">
        <img :width="width" src="/assets/logo/logo-medis.jpg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    width: { type: Number, default: 150 },
    justifyContent: { type: String, default: "space-around" },
  },
};
</script>

<style></style>
