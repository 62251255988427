<template>
  <div v-bind:style="{
    display:'flex',
    minHeight:'100vh',
    display:'flex',
    justifyContent:'center',
    flexDirection: 'column',
    direction: direction,
  }">

    <WebsiteHeader />

    <router-view v-bind:style="{
      width:'100%',
      padding:'15px',
      minHeight:'80vh',
      flex:1,
    }"></router-view>

    <div :style="{
      background: 'var(--secondary-color)',
      padding: '10px',
      display:'flex',
      justifyContent:'center'
    }">
      <div :style="{
      width:'100%',
      textAlign: 'center'
    }">
        <div :style="{marginBottom: '15px', fontSize:'18px'}">
          {{$t('findUsOn')}}
          <!-- Facebook -->
          <SocialMediaIcon image="assets/icons/facebook-square-brands.svg" link="https://www.facebook.com/HopeHorizon.AI.Startup"/>
          <!-- LinkedIn -->
          <SocialMediaIcon image="assets/icons/linkedin-brands.svg" link="https://www.linkedin.com/company/hope-horizon-world-ai-e-health/"/>
        </div>

        &copy; {{ new Date().getFullYear() }}
        <a
          href="https://hopehorizonworld.com/"
          target="_blank"
          :style="{
            color: 'var(--primary-color)',
            textDecoration:'none'
          }"
        > {{$t('hopeHorizon')}}</a>. {{$t('rightsReserved')}}
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderVue from '../../components/Website/Header.vue'
import SocialMediaIconVue from '../../components/Icons/SocialMediaIcon.vue'
export default {
  components: {
    WebsiteHeader: HeaderVue,
    SocialMediaIcon: SocialMediaIconVue
  },
  computed:{
    ...mapGetters("i18n",{direction: "getDirection"})
  },
  updated(){
    console.log(this.direction)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
