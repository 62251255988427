<template>
  <Card
    :style="{
			width:'100%',
			marginBottom:'15px',
		}"
    :bodyStyle="{
				padding:'15px',
			}"
  >
    <h2 :style="{
			fontWeight:'bold'
		}">{{$t('takeAPCRTest?')}}</h2>
    <AskForLocation @onLocation="getLaboratories"
     />
    <ShowMore v-if="this.laboratories" height="200px">
      <p>{{$t("chooseOneOfTheLaboratoriesToBookAnAppointement")}}</p>
      <a-list
        item-layout="horizontal"
        :loading="isLoading"
        :data-source="laboratories||[]"
      >
        <a-list-item
          slot="renderItem"
          slot-scope="item"
          :style="{display:'flex'}"
        >
          <a-list-item-meta :style="{
						flexGrow:2,
					}">
            <p slot="description">
              {{(item.city?(item.city + ', '):"") + item.governorate}}
            </p>
            <p
              v-if="item.distance"
              slot="description"
            >
              {{item.distance > 1.2
						? Math.round(item.distance*60/50)+" "+$t("mins")+" "+$t("withCar")
						: Math.round(item.distance*60/5)+" "+$t("mins")+" "+$t("onFoot")}}
            </p>
            <h3 slot="title">{{ item.key }}</h3>
          </a-list-item-meta>
          <div :style="{
					display:'flex',
					flexWrap:'wrap',
				}">
            <a :href="'tel:+216'+(item.phones[0]).split(' ').join('')">
              <Button class="action-button" @click="callLab(item)">
                <a-icon type="phone" />{{$t("call")}}
              </Button>
            </a>
            <!--<Button class="action-button">
            <a-icon type="calendar" />{{$t("book")}}
          </Button>-->
          </div>
        </a-list-item>

      </a-list>
    </ShowMore>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
import getNearLaboratories from "../../lib/laboratories/getNearLaboratories"
import ButtonVue from '../Button/Button.vue'
import AskForLocationVue from '../Location/AskForLocation.vue'
import ShowMoreVue from '../ShowMore/ShowMore.vue'
import logCall from "../../lib/caller/logCall"
export default {
  components: {
    Card: CardVue,
    ShowMore: ShowMoreVue,
    Button: ButtonVue,
    AskForLocation: AskForLocationVue
  },
  data: function () {
    return {
      location: null,
      laboratories: null
    }
  },
  methods: {
    async getLaboratories (location) {
      this.location = location.coords;
      this.laboratories = await getNearLaboratories(this.lat, this.long)
    },
    async callLab(item){
      await logCall({
        phone: '+216'+(item.phones[0]).split(' ').join(''),
        recipient:  item.key,
        recipient_location: item.location,
        location:[this.lat, this.long],
      });
    }
  },
  computed: {
    lat () { return this.location.latitude },
    long () { return this.location.longitude },
    isLoading () { return !this.laboratories }
  }
}
</script>

<style scoped>
.action-button {
  height: auto !important;
  min-height: 40px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 5px;
  white-space: pre-wrap;
}
</style>