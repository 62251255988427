<template>
  <router-link to="/">
    <div :style="{
    display:'flex',
    alignItems:'center'
  }">
      <img
        src="/assets/logo/Logo Horizental.svg"
        class="logo"
        alt="Logo of ANNA"
        width="200"
        :v-bind="$attrs"
      >
      <!-- <h1 :style="{
      color:'#06508c',
      margin:'0 0 0 15px', 
      fontFamily:'Circular Std'
    }">Anna</h1> -->
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["with-text"]
}
</script>

<style scoped>
.logo {
  animation: 1s appear;
  margin: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
</style>
