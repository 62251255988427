import jsonFetch from "../fetcher/jsonFetch"

export default function predictPcr(data){
	const organization = window.location.hostname.split(".")[0];
	return jsonFetch("/api/covid/pcr/predict", {
		method:'post',
		body: {
			...data,
			ORGANIZATION:organization,
		},
	})
}
