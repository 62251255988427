var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.location === null)?_c('Card',{style:({
      backgroundColor:'#fffbe6',
      borderColor:'#ffe58f',
  }),attrs:{"bodyStyle":{
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'center',
    flexDirection:'row'
  }}},[_c('a-icon',{style:({
		color:'#faad14',
		fontSize: '24px'
	}),attrs:{"type":"exclamation-circle"}}),_c('p',{style:({width:'100%', padding:'0 15px'})},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('Button',{staticClass:"allow-button",on:{"click":_vm.askForPermission}},[_vm._v(_vm._s(_vm.$t("shareMyLocation")))])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }