<template>
	<a-button 
		v-bind="$attrs"
		v-on="$listeners"
		:style="{
			direction:'ltr',
			boxShadow:'0 4px 8px rgba(var(--primary-color), 0.15)',
			height:'50px',
			fontFamily:'var(--font-family)',
			fontSize:'var(--font-size)'
		}">
		<slot></slot>
	</a-button>
</template>

<script>
export default {
}
</script>

<style>

</style>