var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{style:({
    display:'flex',
    flexFlow:'row wrap',
    justifyContent:'space-between',
    alignItems:'center',
  }),on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._l((_vm.formInputs),function(input,key){return _c('div',{key:key,style:({
        flex:'1 0 '+(input.width||'auto'),
        padding:'0 13px 13px 13px'
      })},[_c('label',[(input.labelOn)?_c('div',{style:({
          fontWeight:'bold',
          marginBottom:'8px',
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between',
        })},[_c('p',[_vm._v(" "+_vm._s(input.props.label)+" "),(input.props && input.props.required)?_c('span',{style:({
            color:'red'
          })},[_vm._v(" * ")]):_vm._e()]),(input.props.hint)?_c('span',{style:({
          })},[_c('a-tooltip',{attrs:{"placement":"topRight","arrow-point-at-center":""}},[_c('a-icon',{style:({
                  fontSize:'20px'
                }),attrs:{"type":"info-circle"}}),_c('template',{slot:"title"},[_vm._v(" "+_vm._s(input.props.hint)+" ")])],2)],1):_vm._e()]):_c('div',{style:({marginTop:'-30px'})},[(input.props.hint)?_c('span',{style:({
            position: 'relative',
            top:'20px',
            marginLeft: '-15px',
            marginRight: '-15px',
            zIndex: '1'
          })},[_c('a-tooltip',{attrs:{"placement":"topLeft","arrow-point-at-center":""}},[_c('a-icon',{style:({
                  fontSize:'20px',
                  background: 'white',
                  border: '8px solid white'
                }),attrs:{"type":"info-circle"}}),_c('template',{slot:"title"},[_vm._v(" "+_vm._s(input.props.hint)+" ")])],2)],1):_c('div',{style:({height:'40px'})})]),_c(input.type,_vm._b({tag:"component",attrs:{"name":key,"loading":_vm.loading},on:{"change":function($event){return _vm.update(key, $event)}}},'component',input.props,false))],1)])}),_c('RequiredFieldsHelp',{style:({
        marginBottom: '15px',
        flex:'1 0 300px',
        maxWidth:'540px'
      })}),_c('div',{style:({
    padding: '0 13px 13px 13px',
    width:'100%'
  })},[(_vm.error)?_c('a-alert',{attrs:{"message":_vm.$t('error'),"description":_vm._f("capitalize")(_vm.error),"type":"error","show-icon":""}}):_vm._e()],1),_c('div',{style:({
    width:'100%',
    display:'flex',
    padding: '0 13px 13px 13px'
  })},[_vm._t("default",null,{"title":"buttons"}),_c('Button',{style:({
      marginLeft:'auto',
      width:'200px'
    }),attrs:{"type":'primary',"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.submitLabel)+" "),_c('a-icon',{attrs:{"type":"arrow-right"}})],1)],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }