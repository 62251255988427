<template>
  <Card
    :style="{
			width:'100%',
			marginBottom:'15px',
      textAlign:'center',
		}"
    :bodyStyle="{
				padding:'15px',
			}"
  >
    <h2 :style="{
			fontWeight:'bold'
		}">{{$t('clickHereIfYouWouldLikeADoctorToCallYou')}}</h2>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEGRgQi2E0mgPhkx5KJDzRhRW9QxmOf2daXa1oG3F82IFxfg/viewform">
    <Button>
      {{$t('fillOutTheForm')}}
    </Button>
    </a>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
import ButtonVue from '../Button/Button.vue'
export default {
  components: {
    Card: CardVue,
    Button: ButtonVue,
  },
  data: function () {
    return {
    }
  },
  methods: {
    
  },
  computed: {
  }
}
</script>

<style scoped>
.action-button {
  height: auto !important;
  min-height: 40px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 5px;
  white-space: pre-wrap;
}
</style>