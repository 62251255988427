var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
			display:'flex',
			flexDirection:'column',
			justifyContent:'center',
			alignItems:'center',
		})},[_c('h1',{style:({
			color:'var(--primary-color)',
			})},[_vm._v(_vm._s(_vm.$t("nothingHereYet"))+"...")]),_c('img',{staticStyle:{"margin":"15px 0"},attrs:{"width":"320","src":"/assets/illustrations/empty-street.svg"}}),_c('p',[_vm._v(_vm._s(_vm.$t("noPredictionTryChoosingAnExperiment")))]),_c('div',{style:({
      display:'flex',
      flexWrap:'wrap',
      justifyContent:'center'
    })},_vm._l((_vm.experiments),function(experiment,index){return _c('router-link',{key:index,class:{disabled: experiment.disabled},attrs:{"to":experiment.link,"title":_vm.$t(experiment.title)}},[_c('Card',{style:({
            margin:'16px',
            minHeight:'300px'
          }),attrs:{"src":experiment.photo,"alt":_vm.$t(experiment.title),"description":_vm.$t(experiment.description),"title":_vm.$t(experiment.title)}})],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }