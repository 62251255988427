var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  })},[_c('div',{style:({
      maxWidth: 600,
      textAlign: 'center',
      margin: '48px 0',
    })},[_c('Logo'),_c('h1',[_vm._v(_vm._s(_vm.$t("welcomeToAnna")))]),_c('p',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.$t("welcome.description")))]),_c('p',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.$t("welcome.chooseALanguage")))]),_c('div',{style:({
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'row wrap',
      })},_vm._l((_vm.langs),function(lang,index){return _c('Button',{key:index,style:({
          margin: '20px',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          height: '120px',
          fontSize: '24px',
          fontFamily: 'Inter',
          border: 'white',
        }),on:{"click":function($event){return _vm.startExperiment(lang.value)}}},[_c('img',{style:({ height: '80px', marginTop: '3px' }),attrs:{"src":lang.image}}),_vm._v(" "+_vm._s(lang.label)+" ")])}),1)],1),_c('div',{style:({ width: '100%', maxWidth: '600px' })},[_c('hr',{style:({
        marginBottom: '15px',
        border: 'solid 1px #DDD',
        maxWidth: '50px',
      })}),_c('Sponsors',{attrs:{"justifyContent":'center',"width":100}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }