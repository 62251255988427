<template>
  <a-card
    hoverable
    style="width: 200px"
  >
    <img
      slot="cover"
      :alt="alt"
      :src="src"
      :style="{
        padding:'10px'
        }"
    />
    <a-card-meta :title="title">
      <template slot="description">
        {{description}}
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    title: String,
    description: String,
  }
}
</script>

<style>
</style>