<template>
  <div v-bind:style="{
			display:'flex',
			flexDirection:'column',
			justifyContent:'center',
			alignItems:'center',
		}">
    <h1 v-bind:style="{
			color:'var(--primary-color)',
			}">{{ $t("nothingHereYet") }}...</h1>
    <img
      style="margin:15px 0"
      width="320"
      src="/assets/illustrations/empty-street.svg"
    >
    <p>{{$t("noPredictionTryChoosingAnExperiment")}}</p>
    <div :style="{
      display:'flex',
      flexWrap:'wrap',
      justifyContent:'center'
    }">
      <router-link
        v-for="(experiment,index) in experiments"
        :to="experiment.link"
        :key="index"
        :class="{disabled: experiment.disabled}"
        :title="$t(experiment.title)"
      >
        <Card
          :src="experiment.photo"
          :alt="$t(experiment.title)"
          :description="$t(experiment.description)"
          :title="$t(experiment.title)"
          :style="{
            margin:'16px',
            minHeight:'300px'
          }"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import CardWithPhotoVue from '../Card/CardWithPhoto.vue'

export const EXPERIMENTS = [
  {
    title: "covid19Positivity.title",
    description: "covid19Positivity.description",
    photo: "/assets/illustrations/scan.svg",
    link: "/dashboard/experiments/covid19Positivity"
  },{
    title: "covid19Severity.title",
    description: "covid19Severity.description",
    photo: "/assets/illustrations/doctors.svg",
    link: "/dashboard/experiments/covid19Severity",
    disabled:true,
  },
  {
    title: "nonInvasiveVentilation.title",
    description: "nonInvasiveVentilation.description",
    photo: "/assets/illustrations/doctor.svg",
    link: "/dashboard/experiments/nonInvasiveVentilation",
    disabled:true,
  }
]

export default {
  data: function () {
    return {
      experiments: EXPERIMENTS,
    };
  },
  components: {
    Card: CardWithPhotoVue,
  }
}
</script>

<style scoped>
.disabled{
  pointer-events:none;
  opacity: 0.6;
  cursor: not-allowed;
}
</style>