

export default function jsonFetch(url, options={}){
	options.headers = {
		...options.headers,
		"Content-Type":"application/json"
	}
	if(options.body){
		options.body = JSON.stringify(options.body)
	}
	return fetch(url, options)
		.then(async response=>{
			if(response.ok){
				return response.json()
			}
			throw await response.json()
		})
}