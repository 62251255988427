<template>
  <Card
    :bodyStyle="{
        padding:'15px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'left',
        alignItems: 'center',
        width:'100%',
    }"
    :style="{
        cursor:'pointer',
        marginBottom:'15px',
        boxShadow: isSelected ? '0 0 0px 2px var(--primary-color)':'none',
        }"
    @click="toggleSelection"
  >
    <div :style="{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    }">
      <img
        v-if="icon"
        :src="icon"
        :alt="label"
        width="50"
        :style="{
          /*margin:'0 15px'*/
      }"
      />
      <p :style="{
        margin:'0 15px'
    }">
        {{label}}
      </p>
    </div>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
export default {
  components: {
    Card: CardVue
  },
  props: {
    label: String,
    icon: String,
  },
  data: function () {
    return {
      selected: false,
    };
  },
  methods: {
    toggleSelection () {
      this.selected = !this.selected
      this.$emit('change', this.selected)
    }
  },
  computed: {
    isSelected () {
      return this.selected
    }
  }
}
</script>

<style>
</style>