var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{style:({
			width:'100%',
			marginBottom:'15px',
		}),attrs:{"bodyStyle":{
				padding:'15px',
			}}},[_c('h2',{style:({
			fontWeight:'bold'
		})},[_vm._v(_vm._s(_vm.$t('takeAPCRTest?')))]),_c('AskForLocation',{on:{"onLocation":_vm.getLaboratories}}),(this.laboratories)?_c('ShowMore',{attrs:{"height":"200px"}},[_c('p',[_vm._v(_vm._s(_vm.$t("chooseOneOfTheLaboratoriesToBookAnAppointement")))]),_c('a-list',{attrs:{"item-layout":"horizontal","loading":_vm.isLoading,"data-source":_vm.laboratories||[]},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{style:({display:'flex'})},[_c('a-list-item-meta',{style:({
						flexGrow:2,
					})},[_c('p',{attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s((item.city?(item.city + ', '):"") + item.governorate)+" ")]),(item.distance)?_c('p',{attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(item.distance > 1.2 ? Math.round(item.distance*60/50)+" "+_vm.$t("mins")+" "+_vm.$t("withCar") : Math.round(item.distance*60/5)+" "+_vm.$t("mins")+" "+_vm.$t("onFoot"))+" ")]):_vm._e(),_c('h3',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.key))])]),_c('div',{style:({
					display:'flex',
					flexWrap:'wrap',
				})},[_c('a',{attrs:{"href":'tel:+216'+(item.phones[0]).split(' ').join('')}},[_c('Button',{staticClass:"action-button",on:{"click":function($event){return _vm.callLab(item)}}},[_c('a-icon',{attrs:{"type":"phone"}}),_vm._v(_vm._s(_vm.$t("call"))+" ")],1)],1)])],1)}}],null,false,1090717807)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }