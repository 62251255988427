var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
    display:'flex',
    flexFlow:'row wrap',
    justifyContent:'space-around',
    alignItems:'center',
    marginBottom:'50px'
  })},[_c('div',{style:({
      width:'100%',
      maxWidth:'500px',
      padding:'25px'
    })},[_c('h1',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.description))])]),_c('img',{attrs:{"width":"300","src":_vm.photo}})]),_c('div',{style:({
    display:'flex',
    flexFlow:'row wrap',
    justifyContent:'space-around',
    alignItems:'start',
    marginBottom:'150px'
  })},[_c('div',{style:({
    width:'100%',
    maxWidth:'600px',
    padding:'25px'
  })},[_c('p',[_vm._v(_vm._s(_vm.formMessage))]),_c(_vm.form,{tag:"component",attrs:{"formInputs":_vm.formInputs,"submitLabel":_vm.$t('predict'),"getErrorMessage":_vm.getErrorMessage,"loading":_vm.loading},on:{"submit":_vm.$listeners.submit,"change":function($event){return _vm.$emit('change',$event)}}})],1),_c('div',{style:({
      margin:'15px',
    })},[_c('div',{style:({
        marginBottom:'15px'
      })},[_vm._t("default",null,{"title":"result"})],2),_c('Card',{style:({
        marginBottom: '15px',
        flex:'1 0 300px',
        maxWidth:'400px'})},[_c('Sponsors')],1),_c('Help',{style:({
        marginBottom: '15px',
        flex:'1 0 300px',
        maxWidth:'400px'
      })}),_c('ContactUs',{style:({
        marginBottom: '15px',
        flex:'1 0 300px',
        maxWidth:'400px'
      })})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }