<template>
	<a-card 
		v-bind="$attrs" 
		v-on="$listeners"
		:style="{
			borderRadius: 'var(--border-radius)'
		}">
		<slot></slot>
	</a-card>
</template>

<script>

export default {
	inheritAttrs:true,
};
</script>