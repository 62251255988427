<template>
  <Card>
    <div :style="{opacity:.8}">
      <p :style="{margin:0}">
        <span :style="{
              color:'red'
            }">
          *
        </span>
        {{$t("indicatesThatTheFieldIsRequired")}}
      </p>
    </div>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
export default { 
  components:{
    Card:CardVue
  }

}
</script>

<style>
</style>