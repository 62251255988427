<template>
  <Card>
    <h2 :style="{
		fontWeight:'bold'
	}">{{$t('somethingNotClear?')}}</h2>
	<p>
		{{$t('hoverOnTheInfoIcon')}}
		<a-icon :style="{fontSize:'18px'}" type="info-circle" />
		{{$t('youWillFindBriefExplanation')}}
	</p>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
export default {
	components:{
		Card:CardVue
	}
}
</script>

<style>
</style>