export default {
	'Tunisia':'Tunisia تونس',
	'Afghanistan': 'Afghanistan ',
	'Albania': 'Albania ',
	'Algeria': 'Algeria ',
	'Andorra': 'Andorra ',
	'Angola': 'Angola ',
	'Anguilla': 'Anguilla ',
	'Antigua and Barbuda': 'Antigua and Barbuda ',
	'Argentina': 'Argentina ',
	'Armenia': 'Armenia ',
	'Aruba': 'Aruba ',
	'Australia': 'Australia ',
	'Austria': 'Austria ',
	'Azerbaijan': 'Azerbaijan ',
	'Bahamas': 'Bahamas ',
	'Bahrain': 'Bahrain ',
	'Bangladesh': 'Bangladesh ',
	'Barbados': 'Barbados ',
	'Belarus': 'Belarus ',
	'Belgium': 'Belgium ',
	'Belize': 'Belize ',
	'Benin': 'Benin ',
	'Bermuda': 'Bermuda ',
	'Bhutan': 'Bhutan ',
	'Bolivia': 'Bolivia ',
	'Bosnia and Herzegovina': 'Bosnia and Herzegovina ',
	'Botswana': 'Botswana ',
	'Brazil': 'Brazil ',
	'British Virgin Islands': 'British Virgin Islands ',
	'Brunei': 'Brunei ',
	'Bulgaria': 'Bulgaria ',
	'Burkina Faso': 'Burkina Faso ',
	'Burundi': 'Burundi ',
	'Cabo Verde': 'Cabo Verde ',
	'Cambodia': 'Cambodia ',
	'Cameroon': 'Cameroon ',
	'Canada': 'Canada ',
	'Caribbean Netherlands': 'Caribbean Netherlands ',
	'Cayman Islands': 'Cayman Islands ',
	'Central African Republic': 'Central African Republic ',
	'Chad': 'Chad ',
	'Channel Islands': 'Channel Islands ',
	'Chile': 'Chile ',
	'China': 'China ',
	'Colombia': 'Colombia ',
	'Comoros': 'Comoros ',
	'Congo': 'Congo ',
	'Costa Rica': 'Costa Rica ',
	'Croatia': 'Croatia ',
	'Cuba': 'Cuba ',
	'Curacao': 'Curacao ',
	'Cyprus': 'Cyprus ',
	'Czechia': 'Czechia ',
	'DRC': 'DRC ',
	'Denmark': 'Denmark ',
	'Djibouti': 'Djibouti ',
	'Dominica': 'Dominica ',
	'Dominican Republic': 'Dominican Republic ',
	'Ecuador': 'Ecuador ',
	'Egypt': 'Egypt ',
	'El Salvador': 'El Salvador ',
	'Equatorial Guinea': 'Equatorial Guinea ',
	'Eritrea': 'Eritrea ',
	'Estonia': 'Estonia ',
	'Eswatini': 'Eswatini ',
	'Ethiopia': 'Ethiopia ',
	'Faeroe Islands': 'Faeroe Islands ',
	'Falkland Islands': 'Falkland Islands ',
	'Fiji': 'Fiji ',
	'Finland': 'Finland ',
	'France': 'France ',
	'French Guiana': 'French Guiana ',
	'French Polynesia': 'French Polynesia ',
	'Gabon': 'Gabon ',
	'Gambia': 'Gambia ',
	'Georgia': 'Georgia ',
	'Germany': 'Germany ',
	'Ghana': 'Ghana ',
	'Gibraltar': 'Gibraltar ',
	'Greece': 'Greece ',
	'Greenland': 'Greenland ',
	'Grenada': 'Grenada ',
	'Guadeloupe': 'Guadeloupe ',
	'Guatemala': 'Guatemala ',
	'Guinea': 'Guinea ',
	'Guinea-Bissau': 'Guinea-Bissau ',
	'Guyana': 'Guyana ',
	'Haiti': 'Haiti ',
	'Honduras': 'Honduras ',
	'Hong Kong': 'Hong Kong ',
	'Hungary': 'Hungary ',
	'Iceland': 'Iceland ',
	'India': 'India ',
	'Indonesia': 'Indonesia ',
	'Iran': 'Iran ',
	'Iraq': 'Iraq ',
	'Ireland': 'Ireland ',
	'Isle of Man': 'Isle of Man ',
	'Italy': 'Italy ',
	'Ivory Coast': 'Ivory Coast ',
	'Jamaica': 'Jamaica ',
	'Japan': 'Japan ',
	'Jordan': 'Jordan ',
	'Kazakhstan': 'Kazakhstan ',
	'Kenya': 'Kenya ',
	'Kuwait': 'Kuwait ',
	'Kyrgyzstan': 'Kyrgyzstan ',
	'Laos': 'Laos ',
	'Latvia': 'Latvia ',
	'Lebanon': 'Lebanon ',
	'Lesotho': 'Lesotho ',
	'Liberia': 'Liberia ',
	'Libya': 'Libya ',
	'Liechtenstein': 'Liechtenstein ',
	'Lithuania': 'Lithuania ',
	'Luxembourg': 'Luxembourg ',
	'Macao': 'Macao ',
	'Madagascar': 'Madagascar ',
	'Malawi': 'Malawi ',
	'Malaysia': 'Malaysia ',
	'Maldives': 'Maldives ',
	'Mali': 'Mali ',
	'Malta': 'Malta ',
	'Marshall Islands': 'Marshall Islands ',
	'Martinique': 'Martinique ',
	'Mauritania': 'Mauritania ',
	'Mauritius': 'Mauritius ',
	'Mayotte': 'Mayotte ',
	'Mexico': 'Mexico ',
	'Moldova': 'Moldova ',
	'Monaco': 'Monaco ',
	'Mongolia': 'Mongolia ',
	'Montenegro': 'Montenegro ',
	'Montserrat': 'Montserrat ',
	'Morocco': 'Morocco ',
	'Mozambique': 'Mozambique ',
	'Myanmar': 'Myanmar ',
	'Namibia': 'Namibia ',
	'Nepal': 'Nepal ',
	'Netherlands': 'Netherlands ',
	'New Caledonia': 'New Caledonia ',
	'New Zealand': 'New Zealand ',
	'Nicaragua': 'Nicaragua ',
	'Niger': 'Niger ',
	'Nigeria': 'Nigeria ',
	'North Macedonia': 'North Macedonia ',
	'Norway': 'Norway ',
	'Oman': 'Oman ',
	'Pakistan': 'Pakistan ',
	'Palestine': 'Palestine ',
	'Panama': 'Panama ',
	'Papua New Guinea': 'Papua New Guinea ',
	'Paraguay': 'Paraguay ',
	'Peru': 'Peru ',
	'Philippines': 'Philippines ',
	'Poland': 'Poland ',
	'Portugal': 'Portugal ',
	'Qatar': 'Qatar ',
	'Reunion': 'Reunion ',
	'Romania': 'Romania ',
	'Russia': 'Russia ',
	'Rwanda': 'Rwanda ',
	'S. Korea': 'S. Korea ',
	'Saint Kitts and Nevis': 'Saint Kitts and Nevis ',
	'Saint Lucia': 'Saint Lucia ',
	'Saint Martin': 'Saint Martin ',
	'Saint Pierre Miquelon': 'Saint Pierre Miquelon ',
	'San Marino': 'San Marino ',
	'Sao Tome and Principe': 'Sao Tome and Principe ',
	'Saudi Arabia': 'Saudi Arabia ',
	'Senegal': 'Senegal ',
	'Serbia': 'Serbia ',
	'Seychelles': 'Seychelles ',
	'Sierra Leone': 'Sierra Leone ',
	'Singapore': 'Singapore ',
	'Sint Maarten': 'Sint Maarten ',
	'Slovakia': 'Slovakia ',
	'Slovenia': 'Slovenia ',
	'Solomon Islands': 'Solomon Islands ',
	'Somalia': 'Somalia ',
	'South Africa': 'South Africa ',
	'South Sudan': 'South Sudan ',
	'Spain': 'Spain ',
	'Sri Lanka': 'Sri Lanka ',
	'St. Barth': 'St. Barth ',
	'St. Vincent Grenadines': 'St. Vincent Grenadines ',
	'Sudan': 'Sudan ',
	'Suriname': 'Suriname ',
	'Sweden': 'Sweden ',
	'Switzerland': 'Switzerland ',
	'Syria': 'Syria ',
	'Taiwan': 'Taiwan ',
	'Tajikistan': 'Tajikistan ',
	'Tanzania': 'Tanzania ',
	'Thailand': 'Thailand ',
	'Timor-Leste': 'Timor-Leste ',
	'Togo': 'Togo ',
	'Trinidad and Tobago': 'Trinidad and Tobago ',
	'Turkey': 'Turkey ',
	'Turks and Caicos': 'Turks and Caicos ',
	'UAE': 'UAE ',
	'USA': 'USA ',
	'Uganda': 'Uganda ',
	'Ukraine': 'Ukraine ',
	'United Kingdom': 'United Kingdom ',
	'Uruguay': 'Uruguay ',
	'Uzbekistan': 'Uzbekistan ',
	'Vatican City': 'Vatican City ',
	'Venezuela': 'Venezuela ',
	'Vietnam': 'Vietnam ',
	'Wallis and Futuna': 'Wallis and Futuna ',
	'Western Sahara': 'Western Sahara ',
	'Yemen': 'Yemen ',
	'Zambia': 'Zambia ',
	'Zimbabwe': 'Zimbabwe ',
}