<template>
  <div
    id="app"
    :style="{
      '--font-size': fontSize+'px',
    }"
  >
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import changeLang from "./lib/head/changeLang"
export default {
  created () {
    changeLang(this.$i18n.locale);
  },
  computed: {
    ...mapGetters("i18n", { fontSize: "getFontSize" }),
  },
}
</script>

<style lang="less">
//@import '/fonts/mainFont/font.css';
//@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;800&display=swap");
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app p {
  font-size: var(--font-size);
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
:root {
  --primary-color: #035aef;
  --text-color: #848484;
  --border-radius: 5px;
  --font-family: 'Inter', sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  letter-spacing: -1px;
  /*font-weight: bold;*/
}
h2 {
  font-family: var(--font-family);
  font-weight: normal !important;
}
</style>
