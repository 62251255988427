<template>
  <div :style="{
      display:'flex',
      maxWidth:'1000px',
      margin:'auto',
      width:'100%',
      justifyContent:'space-between',
      alignItems:'center',
      flexDirection:'row',
      padding:'15px',
  }">
    <Logo with-text />
    <language-selector />
  </div>
</template>

<script>
import LanguageSelectorVue from '../Language/LanguageSelector.vue'
import LogoVue from '../Logo/Logo.vue'
export default {
  components: {
    Logo: LogoVue,
    LanguageSelector: LanguageSelectorVue
  }
}
</script>

<style>
</style>