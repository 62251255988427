var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{style:({
      cursor:'pointer',
      marginBottom:'15px',
      boxShadow: _vm.isSelected ? '0 0 0px 2px var(--primary-color)':'none',
      }),attrs:{"bodyStyle":{
      padding:'15px',
      display:'flex',
      flexDirection:'row',
      justifyContent:'left',
      alignItems: 'center',
      width:'100%',
  }},on:{"click":_vm.toggleSelection}},[_c('div',{style:({
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  })},[(_vm.icon)?_c('img',{style:({
        /*margin:'0 15px'*/
    }),attrs:{"src":_vm.icon,"alt":_vm.label,"width":"50"}}):_vm._e(),_c('p',{style:({
      margin:'0 15px'
  })},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }