var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position:'fixed',
    top:0,
    left:0,
    padding:'15px',
    background:'white',
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    zIndex:999,
    overflow:'auto'
  })},[_c('a-icon',{style:({
      fontSize:'18px',
        position:'absolute',
        top:'40px',
        right:'40px',
      }),attrs:{"type":"close"},on:{"click":_vm.close}}),_c('div',{style:({
    padding: '60px 10px',
    margin:'auto',
    width:'100%',
    maxWidth:'600px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-around'
  })},[_c('div',{style:({
      textAlign:'center',
      fontSize:'60px',
      marginBottom:'15px',
    })},[_c('h1',{style:({fontSize:'40px', marginBottom:'0px'})},[_vm._v(_vm._s(_vm.title))]),_c('h2',{style:({fontSize:'20px'})},[_vm._v(_vm._s(_vm.subtitle))])]),_c('a-alert',{style:({
        width:'100%',
        bottom:'15px',
      }),attrs:{"type":_vm.type,"message":_vm.description,"show-icon":""}}),_vm._t("default",null,{"title":"extra"}),_c('ContactUs',{style:({
      width:'100%'
    })}),_c('Button',{style:({
      width:'100%'
      }),on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }