<template>
  <div :style="{
    position:'relative',
    overflow:'hidden',
    height:collapsed?height:'auto',
  }">
    <div :style="{
      pointerEvents:collapsed ? 'none':'all'
    }">
      <slot>
      </slot>
    </div>
    <div
      v-if="collapsed"
      class="shadow"
      @click="open()"
    >
      <div>
        <a-icon :type="'down'" /> {{$t("showMore")}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '200px'
    }
  },
  data: function () {
    return {
      collapsed: true,
    }
  },
  methods: {
    open () {
      this.collapsed = false;
    }
  }
}
</script>

<style>
.shadow {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 110px;
  box-shadow: 0 -240px 30px -160px white inset;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>