<template>
  <div>
    <Select
      :defaultValue="language"
      :value="language"
      :style="{width:'134px'}"
      @change="setLanguage"
      :options="options"
    >
    </Select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectVue from '../Form/Select.vue'
export default {
  components: {
    Select: SelectVue,
  },
  created () {
    this.loadLanguages()
  },
  data () {
    return { langs: ["fr", "en", "ar"] }
  },
  updated(){
    console.log(this.language);
  },
  beforeUpdate () {
    this.loadLanguages ()
  },
  computed:{
    ...mapGetters("i18n", {language: "getLanguage"})
  },
  methods:{
    ...mapActions("i18n",["setLanguage"]),
    loadLanguages: function(){
      console.log('test');
      this.options = ["fr", "en", "ar"].map(lang => ({
        label: this.$t('lang.' + lang),
        value: lang
      }));
    }
  }
  
}
</script>

<style>
</style>