<template>
  <div :style="{
      position:'fixed',
      top:0,
      left:0,
      padding:'15px',
      background:'white',
      width:'100%',
      height:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      zIndex:999,
      overflow:'auto'
    }">
    <a-icon
      type="close"
      @click="close"
      :style="{
        fontSize:'18px',
          position:'absolute',
          top:'40px',
          right:'40px',
        }"
    />
    <div :style="{
      padding: '60px 10px',
      margin:'auto',
      width:'100%',
      maxWidth:'600px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'space-around'
    }">
      <div :style="{
        textAlign:'center',
        fontSize:'60px',
        marginBottom:'15px',
      }">
        <h1 :style="{fontSize:'40px', marginBottom:'0px'}">{{title}}</h1>
        <h2 :style="{fontSize:'20px'}">{{subtitle}}</h2>
      </div>
      <a-alert
        :type="type"
        :message="description"
        show-icon
        :style="{
          width:'100%',
          bottom:'15px',
        }"
      />
      <slot title="extra">
      </slot>
      <ContactUs :style="{
        width:'100%'
      }" />

      <Button
        @click="close"
        :style="{
        width:'100%'
        }"
      >
        {{$t("close")}}
      </Button>

    </div>

  </div>
</template>

<script>
import ButtonVue from '../Button/Button.vue'
import ContactUsVue from '../Form/ContactUs.vue'

export default {
  components: {
    Button: ButtonVue,
    ContactUs: ContactUsVue,
  },
  props: ["type", "title", "subtitle", "description", "emoji"],
  methods: {
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    this.$root.$el.parentElement.style.overflow = "hidden"
  },
  destroyed () {
    this.$root.$el.parentElement.style.overflow = "auto"
  }
}
</script>

<style>
</style>