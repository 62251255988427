import jsonFetch from "../../lib/fetcher/jsonFetch";


export default {
	mutations: {
		setUser (state, user) {
			state.data = user;
		},
		setAccessToken(state, access_token){
			state.access_token = access_token
		}
	},
	actions: {
		async getUser (ctx) {
			const access_token = ctx.state.access_token;
			ctx.commit("setUser", await jsonFetch("/api/users/me", {
				headers:{
					"Authorization":access_token
				}
			}))
		},
		async signup (ctx, userData) {
			return jsonFetch("/api/users/signup", {
				method: "post",
				body: userData
			})
		},
		login (ctx, userData) {
			return jsonFetch("/api/users/login",{
				method:'post',
				body: userData
			}).then((user)=>{
				ctx.commit('setUser', user)
				return user;
			})
		}
	},
	getters:{
		getUser: ({data})=>data,
		getAccessToken: ({access_token})=>access_token
	}
}