<template>
  <form
    :style="{
      display:'flex',
      flexFlow:'row wrap',
      justifyContent:'space-between',
      alignItems:'center',
    }"
    @submit.prevent="submit"
  >
    <!-- <div :style="{width:'100%'}">
      <pre>
      {{JSON.stringify(data, null, 2)}}
    </pre>
    </div> -->
    <div
      v-for="(input, key) in formInputs"
      :key="key"
      :style="{
          flex:'1 0 '+(input.width||'auto'),
          padding:'0 13px 13px 13px'
        }"
    >
      <label>
        <div 
          :style="{
            fontWeight:'bold',
            marginBottom:'8px',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
          }"
          v-if="input.labelOn"
        >
          <p>
            {{input.props.label}}
            <span
              v-if="input.props && input.props.required"
              :style="{
              color:'red'
            }"
            >
              *
            </span>
          </p>

          <span 
            v-if="input.props.hint"
            :style="{
            }"
          >
            <a-tooltip
              placement="topRight"
              arrow-point-at-center
            >
              <a-icon
                :style="{
                    fontSize:'20px'
                  }"
                type="info-circle"
              />
              <template slot="title">
                {{input.props.hint}}
              </template>
            </a-tooltip>
          </span>
        </div>

        <div v-else :style="{marginTop:'-30px'}">
          <span 
            v-if="input.props.hint"
            :style="{
              position: 'relative',
              top:'20px',
              marginLeft: '-15px',
              marginRight: '-15px',
              zIndex: '1'
            }"
          >
            <a-tooltip
              placement="topLeft"
              arrow-point-at-center
            >
              <a-icon
                :style="{
                    fontSize:'20px',
                    background: 'white',
                    border: '8px solid white'
                  }"
                type="info-circle"
              />
              <template slot="title">
                {{input.props.hint}}
              </template>
            </a-tooltip>
          </span>
          <div v-else :style="{height:'40px'}"/>
        </div>
        <component
          :is="input.type"
          v-bind="input.props"
          :name="key"
          :loading="loading"
          @change="update(key, $event)"
        />
        
      </label>
    </div>
    <RequiredFieldsHelp :style="{
          marginBottom: '15px',
          flex:'1 0 300px',
          maxWidth:'540px'
        }" />
    <div :style="{
      padding: '0 13px 13px 13px',
      width:'100%'
    }">
      <a-alert
        v-if="error"
        :message="$t('error')"
        :description="error |capitalize"
        type="error"
        show-icon
      />
    </div>
    
    <div :style="{
      width:'100%',
      display:'flex',
      padding: '0 13px 13px 13px'
    }">
      
      <slot title="buttons" />
      <Button
        :type="'primary'"
        :loading="loading"
        :style="{
        marginLeft:'auto',
        width:'200px'
      }"
      >
        {{submitLabel}}
        <a-icon type="arrow-right" />
      </Button>
    </div>
  </form>
</template>

<script>
import ButtonVue from '../Button/Button.vue';
import RequiredFieldsHelpVue from './RequiredFieldsHelp.vue';
export default {
  components: {
    Button: ButtonVue,
    RequiredFieldsHelp: RequiredFieldsHelpVue
  },
  props: {
    loading: Boolean,
    getErrorMessage: Function,
    formInputs: Object,
    submitLabel: { type: String, default: "submit" },
    error: String,
  },
  data: function () {
    return {
      data: {}
    };
  },
  mounted(){
    Object.entries(this.formInputs).forEach(([key, input])=>{
      if(input.props && typeof input.props.value != "undefined"){
        this.update(key, input.props.value)
      }
    })
  },
  methods: {
    update (key, value) {
      this.$set(this.data, key, value)
      this.$emit("change", this.data)
    },
    submit () {
      const errorMessages = Object.entries(this.formInputs)
        .map(([key, { validate }]) => {
          return validate && validate(this.data[key], key, this.data)
        })
        .filter(valid => valid)
      if (errorMessages.length) {
        alert(
          errorMessages.join("\n")
        )
        return;
      }
      this.$emit("submit", this.data);
    }
  }
}
</script>

<style>
</style>