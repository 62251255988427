<template>
  <Card
    v-if="location === null"
    :style="{
      backgroundColor:'#fffbe6',
      borderColor:'#ffe58f',
  }"
    :bodyStyle="{
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'center',
    flexDirection:'row'
  }"
  >
    <a-icon
      type="exclamation-circle"
      :style="{
		color:'#faad14',
		fontSize: '24px'
	}"
    />
    <p :style="{width:'100%', padding:'0 15px'}">
      {{message}}
    </p>
    <Button
      class="allow-button"
      @click="askForPermission"
    >{{$t("shareMyLocation")}}</Button>
  </Card>
</template>

<script>
import getLocation from '../../lib/locations/getLocation'
import ButtonVue from '../Button/Button.vue'
import CardVue from '../Card/Card.vue';
export default {
  components: {
    Button: ButtonVue,
    Card: CardVue
  },
  data: function () {
    return {
      location: null,
    };
  },
  methods: {
    askForPermission () {
      getLocation()
        .then(loc => {
          console.log(loc)
          this.location = loc;
          this.$emit("onLocation", loc);
        })
        .catch((e) => {
          console.log(e)
          this.location = null;
          this.$emit("onLocation", null);
        })
    }
  },
  computed: {
    message () {
      return this.location
        ? this.$t("youAreIn")
        : this.$t("youNeedToAllowLocationInOrderToSeeLabs")
    }
  }
}
</script>

<style>
.allow-button{
  white-space:pre-wrap;
}
</style>