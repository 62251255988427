export default {
	'ariana':'ariana أريانة',
	'beja':'beja باجة',
	'ben arous':'ben arous بن عروس',
	'bizerte':'bizerte بنزرت',
	'gabes':'gabes قابس',
	'gafsa':'gafsa قفصة',
	'jendouba':'jendouba جندوبة',
	'kairouan':'kairouan قيروان',
	'kasserine':'kasserine قصرين',
	'kebili':'kebili قبلي',
	'kef':'kef كاف',
	'mahdia':'mahdia مهدية',
	'manouba':'manouba منوبة',
	'medenine':'medenine مدنين',
	'monastir':'monastir منستير',
	'nabeul':'nabeul نابل',
	'sfax':'sfax صفاقس',
	'sidi bouzid':'sidi bouzid سيدي بوزيد',
	'siliana':'siliana سليانة',
	'sousse':'sousse سوسة',
	'tataouine':'tataouine تطاوين',
	'tozeur':'tozeur توزر‎',
	'tunis':'tunis تونس',
	'zaghouane':'zaghouane زغوان',
}