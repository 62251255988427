var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
  display:'flex',
  minHeight:'100vh',
  display:'flex',
  justifyContent:'center',
  flexDirection: 'column',
  direction: _vm.direction,
})},[_c('WebsiteHeader'),_c('router-view',{style:({
    width:'100%',
    padding:'15px',
    minHeight:'80vh',
    flex:1,
  })}),_c('div',{style:({
    background: 'var(--secondary-color)',
    padding: '10px',
    display:'flex',
    justifyContent:'center'
  })},[_c('div',{style:({
    width:'100%',
    textAlign: 'center'
  })},[_c('div',{style:({marginBottom: '15px', fontSize:'18px'})},[_vm._v(" "+_vm._s(_vm.$t('findUsOn'))+" "),_c('SocialMediaIcon',{attrs:{"image":"assets/icons/facebook-square-brands.svg","link":"https://www.facebook.com/HopeHorizon.AI.Startup"}}),_c('SocialMediaIcon',{attrs:{"image":"assets/icons/linkedin-brands.svg","link":"https://www.linkedin.com/company/hope-horizon-world-ai-e-health/"}})],1),_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),_c('a',{style:({
          color: 'var(--primary-color)',
          textDecoration:'none'
        }),attrs:{"href":"https://hopehorizonworld.com/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('hopeHorizon')))]),_vm._v(". "+_vm._s(_vm.$t('rightsReserved'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }