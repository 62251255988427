<template>
  <div :style="{
    display:'flex',
    justifyContent:'center'
  }">
    <Experiment
      :title="$t(experiment.title)"
      :description="$t(experiment.description)"
      :photo="experiment.photo"
      :formMessage="$t('fillOutTheFormToGetPrediction')"
      :form="form"
      :formInputs="formInputs"
      :loading="loading"
      @submit="submit"
      @change="onUpdate"
    >
      <slot title="result">
        <PredictionResult
          v-if="predictionResult!==null"
          :type="resultType"
          :title="predictionResultTitle"
          :subtitle="predictionResultSubtitle"
          :description="predictionResultDetail"
          @close="clear"
        >
          <slot title="extra">
            <SubscribeForACall v-if="(predictionResult>=25) && isTunisia"/>
            <Laboratories v-if="(predictionResult>=25) && isTunisia" />
          </slot>
        </PredictionResult>
      </slot>
    </Experiment>
  </div>
</template>

<script>
import ExperimentVue from '../../../components/Experiments/Experiment.vue'
import { EXPERIMENTS } from '../../../components/Experiments/ViewExperiments.vue';
import CheckboxVue from '../../../components/Form/Checkbox.vue';
import FormVue from '../../../components/Form/Form.vue'
import InputVue from '../../../components/Form/Input.vue';
import SelectVue from '../../../components/Form/Select.vue';
import PredictionPopup from '../../../components/Prediction/PredictionPopup.vue';
import CITIES from "../../../lib/locations/CITIES";
import COUNTRIES from "../../../lib/locations/COUNTRIES";
import notFalsyValidator from "../../../lib/validators/notFalsyValidator";
import predictPcr from "../../../lib/predictions/predictPcr"
import createGetFieldI18n from "../../../lib/validators/createGetFieldI18n";
import LaboratoriesListVue from '../../../components/Laboratories/LaboratoriesList.vue';
import SubscribeForACallVue from '../../../components/CallDoctor/SubscribeForACall.vue';

export default {
  data: function () {
    return {
      predictionResult: null,
      loading: false,
      isTunisia: false
    };
  },
  created () {
    this.experiment = EXPERIMENTS[0]
    this.form = FormVue;
  },
  computed: {
    formInputs () {
      return {
        TEMP: {
          width: '100%',
          type: InputVue,
          labelOn: true,
          props: {
            required: true,
            autocomplete: 'off',
            min: 30,
            max: 42,
            step: "0.1",
            type: 'number',
            label: this.$t("temperature"),
            placeholder: this.$t("example") + ": " + "36.7",
            icon: '/assets/icons/fever.png',
          }
        },
        COUGH: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("cough.word"),
            icon: '/assets/icons/dryCough.png',
            hint: this.$t("cough.hint"),
            style: {
              minWidth: '200px',
            }
          }
        },
        FATIGUE: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("fatigue.word"),
            icon: '/assets/icons/fatigue.png',
            hint: this.$t("fatigue.hint")
          }
        },
        HEADACHE: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("headache.word"),
            icon: '/assets/icons/headache.png',
            hint: this.$t("headache.hint")
          }
        },
        DYSPNEA: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("dyspnea.word"),
            icon: '/assets/icons/dyspnea.png',
            hint: this.$t("dyspnea.hint")
          }
        },
        MYALGIA: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("stiffness.word"),
            icon: '/assets/icons/myalgia.png',
            hint: this.$t("stiffness.hint")
          }
        },
        EXPECTORATION: {
          width: '50%',
          type: CheckboxVue,
          labelOn: false,
          props: {
            label: this.$t("expectoration.word"),
            icon: '/assets/icons/cough.png',
            hint: this.$t("expectoration.hint")
          }
        },
        COUNTRY: {
          width: '100%',
          type: SelectVue,
          labelOn: true,
          validate: notFalsyValidator(this.$t("isRequired"), createGetFieldI18n(this.$t.bind(this))),
          props: {
            required: true,
            label: this.$t("country"),
            placeholder: this.$t('example') + ": " + Object.values(COUNTRIES)[0],
            style: { width: '100%' },
            options: Object.entries(COUNTRIES).map(([key, val]) => ({ label: val, value: key }))
          }
        },
        CITY: {
          width: '100%',
          type: SelectVue,
          labelOn: this.isTunisia,
          validate: !this.isTunisia ? null : notFalsyValidator(this.$t("isRequired"), createGetFieldI18n(this.$t.bind(this))),
          props: {
            required: this.isTunisia,
            label: this.$t("governorate"),
            placeholder: this.$t('example') + ": " + Object.values(CITIES)[0],
            style: { width: '100%', display: this.isTunisia ? null : 'none' },
            options: Object.entries(CITIES).map(([key, val]) => ({ label: val, value: key }))
          }
        }
      }
    },
    gravity () {
      const gravities = [
        { type: "success", infection: this.$t("notInfected") },
        { type: "info", infection: this.$t("notInfected") },
        { type: "warning", infection: this.$t("infected") },
        { type: "error", infection: this.$t("infected") }
      ];
      return gravities[Math.max(Math.ceil(this.predictionResult / 25) - 1, 0)]
    },
    resultType () {
      return this.gravity && this.gravity.type
    },
    predictionResultDetail () {
      if (this.predictionResult < 25)
        return this.$t("prediction0to25");
      if (this.predictionResult < 50)
        return this.$t("prediction26to50");
      if (this.predictionResult < 75)
        return this.$t("prediction51to75");
      if (this.predictionResult < 100)
        return this.$t("prediction76to100");
      return this.$t("youAreProbably") + " " + (this.gravity || {}).infection;
    },
    predictionResultSubtitle () {
      return `${this.$t("chance")} ${this.$t("thatYouAre")} ${this.$t("infected")}`
    },
    predictionResultTitle () {
      return `${this.predictionResult}%`
    },
  },
  methods: {
    submit (data) {
      let result = { ...data };
      ["COUGH", "FATIGUE", "HEADACHE", "DYSPNEA", "EXPECTORATION", "MYALGIA"]
        .forEach(field => {
          result[field] = data[field] ? 1 : 0
        })
      this.loading = true
      predictPcr(result)
        .then((response) => {
          this.predictionResult = response.detail.result;
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    onUpdate (formData) {
      this.isTunisia = (formData["COUNTRY"] == "Tunisia")
    },
    clear () {
      this.predictionResult=null
    }
  },
  components: {
    Experiment: ExperimentVue,
    Laboratories: LaboratoriesListVue,
    PredictionResult: PredictionPopup,
    SubscribeForACall: SubscribeForACallVue,
  }
}
</script>

<style>
</style>