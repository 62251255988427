<template>
  <div
    :style="{
      display: 'flex',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }"
  >
    <div
      :style="{
        maxWidth: 600,
        textAlign: 'center',
        margin: '48px 0',
      }"
    >
      <Logo />
      <h1>{{ $t("welcomeToAnna") }}</h1>
      <p dir="ltr">{{ $t("welcome.description") }}</p>
      <p dir="ltr">{{ $t("welcome.chooseALanguage") }}</p>
      <div
        :style="{
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'row wrap',
        }"
      >
        <Button
          v-for="(lang, index) in langs"
          :key="index"
          :style="{
            margin: '20px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            height: '120px',
            fontSize: '24px',
            fontFamily: 'Inter',
            border: 'white',
          }"
          @click="startExperiment(lang.value)"
        >
          <img
            :src="lang.image"
            :style="{ height: '80px', marginTop: '3px' }"
          />
          {{ lang.label }}
        </Button>
      </div>
    </div>
    <div :style="{ width: '100%', maxWidth: '600px' }">
      <hr
        :style="{
          marginBottom: '15px',
          border: 'solid 1px #DDD',
          maxWidth: '50px',
        }"
      />
      <Sponsors :justifyContent="'center'" :width="100" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ButtonVue from "../../components/Button/Button.vue";
import SponsorsVue from "../../components/Sponsorship/Sponsors.vue";
export default {
  components: {
    Button: ButtonVue,
    Sponsors: SponsorsVue,
  },
  created() {
    this.loadLanguages();
  },
  // beforeUpdate () {
  //   this.loadLanguages ()
  // },
  methods: {
    ...mapActions("i18n", ["setLanguage"]),
    startExperiment(language) {
      this.setLanguage(language);
      this.$router.push({ name: "covid19Positivity" });
    },
    loadLanguages() {
      this.langs = [
        { label: "عربي", image: "assets/icons/Lang_AR.png", value: "ar" },
        { label: "Français", image: "assets/icons/Lang_FR.png", value: "fr" },
        { label: "English", image: "assets/icons/Lang_EN.png", value: "en" },
      ];
    },
  },
};
</script>

<style></style>
