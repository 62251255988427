<template>
	<a-input
		v-bind="$props"
		:style="{
			height:'50px',
			marginBottom:'15px',
		}"
		@change="$emit('change',$event.target.value)"
	/>
</template>

<script>
export default {

}
</script>

<style>
</style>