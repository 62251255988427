import Vue from 'vue'
import { Pagination, Button, Menu, Card, Input, Icon, Alert, Select, Tooltip, List } from 'ant-design-vue'
Vue.component(Pagination.name, Pagination)
Vue.component(Button.name, Button)
Vue.use(Card)
Vue.use(Menu)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Alert)
Vue.use(Select)
Vue.use(Tooltip)
Vue.use(List)

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})