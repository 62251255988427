<template>
  <a-select
	v-bind="$attrs"
	:options="getOptions"
    :style="{
        cursor:'pointer',
        marginBottom:'15px',
    }"
    v-on="$listeners"
  />
</template>

<script>
import capitalize from "../../lib/string/capitalize"

export default {
	props:["options"],
	computed:{
		getOptions(){
			return this.options.map(opt=>({...opt, label:capitalize(opt.label)}))
		}
	}
}
</script>

<style>
</style>