<template>
  <Card :style="{
    marginBottom:'15px',
    }">
    <h2 :style="{
		fontWeight:'bold'
	}">{{$t('needHelp.title')}}</h2>
    <p>
      {{$t('needHelp.description')}}
    </p>
    <p :style="{
		fontWeight:'bold'
	}">
      <a href="mailto:hopehorizon.world@gmail.com">
        info@hopehorizonworld.com
      </a>
    </p>
  </Card>
</template>

<script>
import CardVue from '../Card/Card.vue'
export default {
  components: {
    Card: CardVue
  }
}
</script>

<style>
</style>
